import React from 'react'


//routes
import Router from './route'
//styles
import './App.css'


const App = () => {
    return (
        <Router />
    )
}

export default App