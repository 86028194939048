// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-meeting {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px;
}
.create-meeting h2 {
  max-width: 550px;
  color: white;
  font-size: 32px;
  margin: 0px;
  text-align: center;
}
.create-meeting button {
  background-color: red;
  width: 250px;
}
.create-meeting__meeting-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: skyblue;
  border-radius: 8px;
  margin-top: 24px;
  padding: 12px 48px;
  gap: 8px;
  cursor: pointer;
}
@media (max-width: 350px) {
  .create-meeting__meeting-info {
    flex-direction: column;
    padding: 12px;
  }
}
.create-meeting__meeting-info:hover {
  transform: scale(1.02);
}
.create-meeting__meeting-info span {
  text-align: center;
  font-size: 14px;
}
.create-meeting__copied {
  color: green;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/CreateMeeting/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,aAAA;AACJ;AACI;EACI,gBAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;AACR;AAEI;EACI,qBAAA;EACA,YAAA;AAAR;AAGI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,eAAA;AADR;AAGQ;EAZJ;IAaQ,sBAAA;IACA,aAAA;EAAV;AACF;AAEQ;EACI,sBAAA;AAAZ;AAGQ;EACI,kBAAA;EACA,eAAA;AADZ;AAKI;EACI,YAAA;EACA,gBAAA;AAHR","sourcesContent":[".create-meeting {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;    \n    align-items: center;\n    justify-content: center;\n    gap: 12px;\n    padding: 12px;\n\n    h2 {\n        max-width: 550px;\n        color: white;\n        font-size: 32px;\n        margin: 0px;\n        text-align: center;\n    }\n\n    button {\n        background-color: red;\n        width: 250px;\n    }\n\n    &__meeting-info {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: center;\n        background-color: skyblue;\n        border-radius: 8px;\n        margin-top: 24px;\n        padding: 12px 48px;\n        gap: 8px;\n        cursor: pointer;\n\n        @media (max-width: 350px) {\n            flex-direction: column;\n            padding: 12px;\n        }\n        \n        &:hover{\n            transform: scale(1.02);            \n        }\n\n        span {\n            text-align: center;\n            font-size: 14px;\n        }\n    }\n\n    &__copied {\n        color: green;\n        font-weight: 700;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
