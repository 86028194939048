// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vp-container {
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;
  position: relative;
}
.vp-container__player {
  width: 100%;
  height: 100%;
}
.vp-container:not(:hover) .vp-container__controller {
  display: none;
}
@media (max-width: 596px) {
  .vp-container:not(:hover) .vp-container__controller {
    display: flex;
  }
}
.vp-container__controller {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  gap: 24px;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.vp-container__controller__container, .vp-container__controller__container--call-end, .vp-container__controller__container--video, .vp-container__controller__container--mic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
}
.vp-container__controller__container--mic {
  background-color: silver;
}
.vp-container__controller__container--video {
  background-color: skyblue;
}
.vp-container__controller__container--call-end {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/VideoPlayer/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;AAEI;EACI,aAAA;AAAR;AAEQ;EAHJ;IAIQ,aAAA;EACV;AACF;AAEI;EACI,WAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,eAAA;EACA,6CAAA;AAAR;AAEQ;EAEI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AADZ;AAGY;EAEI,wBAAA;AAFhB;AAKY;EAEI,yBAAA;AAJhB;AAOY;EAEI,qBAAA;AANhB","sourcesContent":[".vp-container {\n    width: 100%;\n    height: 100%;\n    background: #000;\n    overflow: hidden;\n    position: relative;\n\n    &__player {\n        width: 100%;\n        height: 100%;\n    }\n\n    &:not(:hover) &__controller {\n        display: none;\n\n        @media (max-width: 596px) {\n            display: flex;\n        }\n    }\n\n    &__controller {\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        position: absolute;\n        bottom: 0;\n        gap: 24px;\n        padding: 0.5rem;\n        background-color: #0002;\n\n        &__container {\n            $this: &;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            width: 50px;\n            height: 50px;\n            border-radius: 50px;\n            cursor: pointer;\n\n            &--mic {\n                @extend #{$this};\n                background-color: silver;\n            }\n\n            &--video {\n                @extend #{$this};\n                background-color: skyblue;\n            }\n\n            &--call-end {\n                @extend #{$this};\n                background-color: red;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
